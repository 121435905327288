<template>
    <div>
        <div class="page-title">
            单品列表
            <router-link class="new-goods btn" to="/ast/shop/upload">新品上传</router-link>
        </div>

        <div v-if="listLoading" class="loading-block">
            <loader/>
        </div>
        <div v-else-if="error">
            <a-alert type="error" :message="error" banner/>
        </div>
        <div v-else class="goods-list-container">

            <div class="goods-list">

                <template v-for="(item,ik) in list">

                    <div :key="ik" class="goods-item">
                        <div class="goods-tips">审核中</div>
                        <div class="goods-thumb">
                            <img :src="item.thumb_image" alt="">
                        </div>
                        <div class="goods-content">
                            <div class="goods-name">{{ item.product_name }}</div>
                            <div class="goods-attr">
                                <div class="goods-price"><small>￥</small>{{ (item.price/100).toFixed(2) }}</div>
                                <div class="goods-stock">
                                    <template v-if="item.sku_num">
                                        库存 {{ item.sku_num }}
                                    </template>
                                </div>
                            </div>
                        </div>
                    </div>

                </template>

                <div class="goods-item"></div>
                <div class="goods-item"></div>
                <div class="goods-item"></div>
                <div class="goods-item"></div>
                <div class="goods-item"></div>
            </div>

            <div>
                <a-pagination
                        class="st-pagination"
                        v-if="pager.total > 0 "
                        :current="pager.current"
                        :total="pager.total"
                        :page-size="pager.size"
                        :show-total="(total, range) => `${range[0]}-${range[1]},共${total}条数据`"
                        @change="gotoPage"
                />
            </div>
        </div>

    </div>
</template>

<script>
    import Loader from "../../../components/Loader";
    import api from "../../../repo/api";

    export default {
        name: "Goods",
        components: {Loader},
        data() {
            return {
                listLoading: true,
                error: '',
                query: {
                    page: 1
                },
                pager: {
                    defaultCurrent: 1,
                    current: 1,
                    defaultPageSize: 10,
                    total: 1,
                    pageSize: 15,
                },
                list: []
            }
        },
        mounted() {
            this.getFirstPage()
        },
        methods: {
            gotoPage(page) {
                this.query.page = page
                this.getList()
            },
            getFirstPage() {
                this.gotoPage(1)
            },
            getList() {
                this.listLoading = true

                api.get('/ast-shop/review-goods', this.query, (data) => {

                    this.listLoading = false

                    if (data.code !== 0) {
                        this.error = data.msg
                        return
                    }

                    this.pager.current = data.data.current_page
                    this.list = data.data.data

                    if (this.query.page === 1 && this.list.length === 0) {
                        this.$router.push('/ast/shop/upload')
                    }

                    this.pager.total = data.data.total
                    this.pager.size = data.data.per_page

                    window.scrollTo(0, 50)
                })
            }
        }
    }
</script>

<style lang="less" scoped>

    .goods-list {
        display: flex;
        flex-wrap: wrap;
    }

    .goods-name {
        font-size: 14px;
        font-weight: 400;
        color: #333333;
        line-height: 24px;
        margin-bottom: 11px;
    }

    .goods-item {
        width: 232px;
        margin-right: 24px;
        margin-bottom: 32px;
        position: relative;
    }

    .goods-tips {
        position: absolute;
        left: -16px;
        top: 16px;

        width: 56px;
        height: 24px;
        background: #FFAD00;
        font-size: 14px;
        font-weight: 400;
        color: #FFFFFF;
        line-height: 24px;
        text-align: center;
    }

    .goods-attr {
        display: flex;
        justify-content: space-between;
    }

    .goods-thumb {
        width: 232px;
        height: 232px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #F5F5F5;
        padding: 16px;
        margin-bottom: 16px;


        img {
            max-width: 100%;
            max-height: 100%;
        }
    }

    .goods-price {
        font-weight: 400;
        color: rgba(0, 0, 0, 0.8);
        line-height: 12px;
    }

    .goods-stock {
        font-weight: 400;
        color: rgba(0, 0, 0, 0.45);
        line-height: 17px;
        font-size: 12px;
    }

    .st-pagination {
        margin-bottom: 32px;
    }

    .new-goods {
        float: right;

        &:hover {
            color: white;
        }
    }

</style>